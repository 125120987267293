@import url("https://fonts.googleapis.com/css2?family=Epilogue:wght@700&display=swap");

.recommended-stuff {
  //centrar las cosas
  margin-bottom: 20rem;
  // text-align: center;
  display: flex;
  justify-content: center;
}

.mobile {
  display: none;
}

.desktop {
  display: visible;
}

.presentation {
  color: #041b32;
  font-size: 90px;
  text-align: left;
  line-height: 7.5rem;
}

.containerRec {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr;
  gap: 0px 0px;
  grid-auto-flow: row;
  grid-template-areas:
    "title recommended"
    "par recommended-card"
    ". .";
}

.title {
  grid-area: title;
}


//falta centrar la card
.centrar {
  // text-align: center;
  margin-left: 150px;
  margin-top: 2em;
}

.parr-rec {
  text-align: left;
  text-align: justify;
  width: 552px;
  line-height: 30px;
  color: #041b32;
  margin-top: 4rem;
  padding-left: 2rem;
  font-style: normal;
  font-family: "Epilogue";
  font-weight: 400;
  font-size: 20px;
  line-height: 40px;
}

/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
  body {
    // background-color: red;
  }

  .mobile {
    display: contents;
  }

  .desktop {
    // display: none;
  }

  .container-Recommended {
    max-width: 100vw;
    max-height: 100vh;
    display: flex;
    flex-direction: column;
    margin-bottom: 57rem;
  }

  .recommended-stuff {
    margin-top: 15rem;
    //centrar las cosas
    margin-bottom: 33rem;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
  }

  .presentation {
    color: #041b32;
    font-size: 75px;
    text-align: center;
    // margin-left: 4.5rem;
    // text-align: center;
    // text-align: left;
    line-height: 6.5rem;
    margin-top: -3rem;
  }

  .containerRec {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    max-width: 100vw;
  }

  .title {
    // width: 80vw;
    font-size: 20px;
    grid-area: title;
    color: #656565;
  }

  .parr-rec {
    text-align: left;
    text-align: justify;
    width: 80vw;
    line-height: 25px;
    color: #041b32;
    font-style: normal;
    font-family: "Epilogue";
    font-weight: 400;
    font-size: 15px;
    padding-left: 0;
    margin-top: -14rem;
  }

  .recommended {
    grid-area: recommended;
    text-align: center;
    color: #656565;
  }

  //centrar la card
  .centrar {
    margin: 0 auto;
    margin-top: 3em;
  }
}

//galaxy fold
@media only screen and (max-width: 300px) {
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) and (max-width: 1200px) {
  body {
    // background-color: red;
  }

  .mobile {
    display: contents;
  }

  .container-Recommended {
    max-width: 100vw;
    max-height: 100vh;
    padding-bottom: 15rem;
  }

  .recommended-stuff {
    margin-top: 1rem;
    //centrar las cosas
    margin-bottom: 33rem;
    display: flex;
    justify-content: center;
  }

  .presentation {
    color: #041b32;
    font-size: 95px;
    width: 80vw;
    text-align: center;
    line-height: 7rem;
  }

  .containerRec {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    max-width: 100vw;
  }

  .title {
    // padding-left: 5px;
    grid-area: title;
  }

  .recommended {
    grid-area: recommended;
    text-align: center;
    color: #656565;
  }

  //falta centrar la card
  .centrar {
    margin: 0 auto;
    margin-top: 3em;
  }

  .parr-rec {
    // padding-left: 5px;
    text-align: left;
    text-align: justify;
    width: 552px;
    line-height: 30px;
    color: #041b32;
    margin-top: 4rem;
    padding-left: 0rem;
    font-style: normal;
    font-family: "Epilogue";
    font-weight: 400;
    font-size: 20px;
    line-height: 40px;
  }
}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) and (max-width: 990px) {
}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) and (max-width:1200px) {

  .container-Recommended{
    // margin-top: -10rem;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction:column ;
    // margin: auto;
  }
  .recommended-stuff {
    //centrar las cosas
    margin-bottom: 20rem;
    // text-align: center;
    display: flex;
    justify-content: center;
  }

  .mobile {
    display: none;
  }

  .desktop {
    display: visible;
  }

  .presentation {
    color: #041b32;
    font-size: 70px;
    text-align: center;
    line-height: 5rem;
  }

  .containerRec {
    display: block;
    
  }

  .recommended{
    display: flex;
    margin-right: 4rem;
    align-items: center;
    justify-content: center;
  }

  // .containerRec {
  //   display: grid;
  //   grid-template-columns: 1fr 1fr;
  //   grid-template-rows: 1fr;
  //   gap: 0px 0px;
  //   grid-auto-flow: row;
  //   grid-template-areas:
  //     "title recommended"
  //     "par recommended-card"
  //     ". .";
  // }

  .title {
    grid-area: title;
  }

  
  .recommended-stuff {
    width: 60vw;
    margin: 0 auto;
    margin-top: -1rem;
    // margin: 3rem;
    // margin-top: 15rem;
    //centrar las cosas
    margin-bottom: 18rem;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
  }

  //falta centrar la card
  .centrar {
    // text-align: center;
    margin-left: 150px;
    margin-top: 2em;
    margin-right: 3rem;
  }

  .parr-rec {
    text-align: left;
    text-align: justify;
    width: 75vw;
    line-height: 30px;
    color: #041b32;
    margin-top: 4rem;
    font-style: normal;
    font-family: "Epilogue";
    font-weight: 400;
    font-size: 17px;
    line-height: 40px;
  }
}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {
}
