@import url("https://fonts.googleapis.com/css2?family=Epilogue:wght@700&display=swap");

.sub {
  width: 100%;
  height: 100vh;

  margin-top: 0px;
  // background-image: url("../../public/assets/viajeAvion.png");
  // filter: brightness(50%);
  background-repeat: no-repeat;
  background-size: 100%;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  .logo-container {
    margin-top: 15rem;
    margin-bottom: 6rem;
  }

  .photo {
    // padding-top: 120px;
    width: 100vw;
    height: 100vh;
    position: absolute;
    top: 0px;
    left: 0px;
    z-index: 1;

    .img {
      object-fit: cover;
      width: 100vw;
      height: 100vh;
    }
  }

  .logotipo {
    position: relative;
    z-index: 9;
    color: #fcfcfe;
    font-family: "Epilogue", sans-serif;
    font-weight: 700;
    font-size: 55px;
    text-align: center;
    margin-bottom: 0px;
  }

  .link {
    color: #ff813b;
    font-weight: 600;
    text-decoration: none;
  }

  .form {
    background-color: #041b32;
    display: flex;
    flex-direction: column;
    width: 70vw;
    border-radius: 10px;
    height: auto;
    margin-top: 2rem;
    position: relative;
    z-index: 9;
  }

  .title {
    font-style: normal;
    font-family: "Epilogue";
    font-weight: 400;
    font-size: 22px;
    line-height: 10px;
    color: #f1f2f6;
    padding-top: 2rem;
    text-align: center;
  }

  .formSub {
    text-align: center;
    padding-top: 2em;
    padding-bottom: 2em;
  }

  .label-input {
    display: flex;
    margin-left: 3.8rem;
    height: auto;
    color: #b5b7ba;
    font-family: "Epilogue";
    font-style: normal;
    font-weight: 400;
    font-size: 13px;
  }

  .email-input {
    width: 30vw;
    height: 51px;
    background: #404b57;
    border-radius: 8px;
    border: none;
    color: #b5b7ba;
    padding-left: 1em;
    font-size: 16px;
    font-family: "Epilogue";
    font-style: normal;
  }

  .email-input:focus {
    outline: none !important;
  }

  ::placeholder {
    color: #b9b6b6;
    // padding-left: 1em;
    // font-family: "Istok Web";
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 29px;
  }

  .submit-btn {
    margin-left: 10px;
    width: 174px;
    height: 51px;
    background: #ff813b;
    border-radius: 8px;
    border: none;
    //text
    font-style: normal;
    font-family: "Epilogue";
    font-weight: 400;
    font-size: 16px;
    color: #f1f2f6;
  }

  .submit-btn:hover {
    cursor: pointer;
    //como era lo del tiempo de animacion?
    background: #ff813b;
    transition: 300ms;
  }

  .subtitle {
    margin-top: 1.2em;
    font-size: 11px;
    text-align: left;
    margin-left: 3.8rem;
    font-weight: 200;
    color: #b5b7ba;
  }
}

/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
  .sub {
    width: 100vw;
    height: 100vh;

    background-repeat: no-repeat;
    background-size: 100%;
    background-size: cover;

    .form {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      width: 80vw;
      bottom: 1.5rem;
      position: absolute;
    }

    

    .photo {
      // padding-top: 120px;
      width: 100vw;
      height: 100vh;
      position: absolute;
      top: -385px;
      // left: -29px;
      z-index: 1;

      .img {
        object-fit: cover;
        width: 100vw;
        height: 100vh;
      }
    }

    .logo-container {
      position: absolute;
      z-index: 1;

      display: flex;
    }

    .logotipo {
      top: -10rem;
      font-size: 50px;
      text-align: center;
    }

    .title {
      padding: 0;
      font-style: normal;
      width: 80vw;
      font-weight: 400;
      font-size: 13px;
      line-height: 35px;
      color: #f1f2f6;
      width: 100vw;
      // padding-top: 15rem;
    }

    .formSub {
      text-align: center;
      padding: 0;
      padding-top: 0.2em;
      padding-bottom: 0.2em;
      width: 100vw;
    }

    .label-input {
      // margin-left: 6.8rem;
      display: flex;
      height: auto;
      color: #b5b7ba;
      font-family: "Epilogue";
      font-style: normal;
      font-weight: 400;
      font-size: 10px;
    }

    .email-input {
      width: 40vw;
      height: 30px;
      background: rgba(50, 53, 53, 0.83);
      border-radius: 8px;
      border: none;
    }

    ::placeholder {
      color: #b9b6b6;
      // font-family: "Istok Web";
      font-style: normal;
      font-weight: 400;
      font-size: 10px;
      line-height: 200px;
    }

    .submit-btn {
      width: 25vw;
      height: 30px;
      background: #ff813b;
      border-radius: 8px;
      border: none;
      //text
      font-style: normal;
      font-weight: 400;
      font-size: 11px;
      line-height: 30px;
      color: #f1f2f6;
      text-align: center;
    }

    .submit-btn:hover {
      cursor: pointer;
      //como era lo del tiempo de animacion?
      background: #f88f22;
    }

    .subtitle {
      width: 65vw;
      margin-top: 1.2em;
      font-size: 8px;
      text-align: left;
      font-weight: 200;
      color: #b5b7ba;
      margin-bottom: 1.3em;
    }
  }
}

//galaxy fold
@media only screen and (max-width: 300px) {
  .sub {
    width: 100vw;
    height: 100vh;
    min-width: 100vw;
    max-width: 100vw;
    margin-top: 0px;
    background-image: url("../../public/assets/mobile/shifaaz-shamoon-_STLYdogRF4-unsplash.jpg");
    background-repeat: no-repeat;
    background-size: 100%;
    background-size: cover;

    .form {
      display: flex;
      flex-direction: column;

      position: absolute;
      bottom: 0;
    }
    .logo-container {
      width: 100vw;
      height: 100vh;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .logotipo {
      
      width: 80vw;
      height: 20vh;
      object-fit: contain;
    }

    .title {
      padding: 0;
      margin: 0 auto;
      font-style: normal;
      width: 80vw;
      font-weight: 400;
      font-size: 11px;
      line-height: 35px;
      color: #ffffff;
      width: 100vw;
      // padding-top: 15rem;
    }

    .formSub {
      text-align: center;
      padding: 0;
      padding-top: 0.2em;
      width: 100vw;
    }

    .email-input {
      width: 40vw;
      height: 30px;
      background: rgba(50, 53, 53, 0.83);
      border-radius: 8px;
      border: none;
    }

    ::placeholder {
      color: #b9b6b6;
      // padding-left: 1em;
      // font-family: "Istok Web";
      font-style: normal;
      font-weight: 400;
      font-size: 10px;
      line-height: 200px;
      padding-left: 0;
    }

    .submit-btn {
      width: 40vw;
      height: 30px;
      background: #ff813c;
      border-radius: 8px;
      border: none;
      //text
      font-style: normal;
      font-weight: 400;
      font-size: 13px;
      line-height: 32px;
      color: #f3efef;
    }

    .submit-btn:hover {
      cursor: pointer;
      //como era lo del tiempo de animacion?
      background: #f88f22;
    }

    .subtitle {
      width: 80vw;
      margin: auto;
      text-align: left;
      margin-top: 1em;
      font-size: 9px;
      font-weight: 200;
      margin-bottom: 1em;
      color: #ffffff;
    }
  }
}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) and (max-width: 990px) {
  .sub {
    width: 100vw;
    height: 100vh;
    min-width: 100vw;
    max-width: 100vw;
    margin-top: 0px;
    // background-image: url("../../public/assets/mobile/shifaaz-shamoon-_STLYdogRF4-unsplash.jpg");
    background-repeat: no-repeat;
    background-size: 100%;
    background-size: 100%;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    .logo-container {
      margin-top: -10rem;
      margin-bottom: 6rem;
      text-align: center;
    }

    .photo {
      // padding-top: 120px;
      width: 100vw;
      height: 100vh;
      position: absolute;
      top: 0px;
      left: 0px;
      z-index: 1;

      .img {
        top: 0px;
        object-fit: cover;
        width: 100vw;
        height: 100vh;
      }
    }

    .logotipo {
      
      color: #fcfcfe;
      font-family: "Epilogue", sans-serif;
      font-weight: 700;
      font-size: 90px;
    }

    .form {
      display: flex;
      flex-direction: column;
      width: 80vw;
      border-radius: 8px;
      margin-bottom: 3rem;
    }

    .title {
      font-style: normal;
      font-weight: 400;
      font-size: 20px;
      line-height: 15px;
      color: #ffffff;
      padding-top: 2rem;
      text-align: center;
      width: 80vw;
    }

    .formSub {
      text-align: center;
      padding-top: 1em;
      width: 100vw;
      padding-bottom: 2rem;
      height: 100%;
      width: 80vw;
    }

    .label-input {
      display: flex;
      margin-left: 8.5rem;
      height: auto;
      color: #b5b7ba;
      font-family: "Epilogue";
      font-style: normal;
      font-weight: 400;
      font-size: 13px;
    }

    .email-input {
      width: 371px;
      height: 51px;
      background: rgba(50, 53, 53, 0.83);
      border-radius: 8px;
      border: none;
    }

    ::placeholder {
      color: #b9b6b6;
      padding-left: 1em;
      // font-family: "Istok Web";
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 29px;
    }

    .submit-btn {
      margin-left: 10px;
      width: 174px;
      height: 51px;
      background: #ff813c;
      border-radius: 8px;
      border: none;
      //text
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 32px;
      color: #f3efef;
    }

    .submit-btn:hover {
      cursor: pointer;
      //como era lo del tiempo de animacion?
      background: #f88f22;
    }

    .subtitle {
      margin-top: 1em;
      font-size: 14px;
      font-weight: 200;
      color: #ffffff;
      text-align: center;
    }
  }
}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) and (max-width: 1200px) {
  .sub {
    width: 100%;
    height: 1000px;
    margin-top: 0px;
    // background-image: url("../../public/assets/camille-minouflet-d7M5Xramf8g-unsplash.jpg");
    background-repeat: no-repeat;
    background-size: 100%;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    .logo-container {
      margin-top: 0rem;
      margin-bottom: 6rem;
    }

    .photo {
      // padding-top: 120px;
      width: 100vw;
      height: 100vh;
      position: absolute;
      top: 0px;
      left: 0px;
      z-index: 1;

      .img {
        top: 0px;
        object-fit: cover;
        width: 100vw;
        height: 100vh;
      }
    }

    .logotipo {
      // image-rendering: optimizeQuality;
      // object-fit: cover;

      // height: 30vh; //70
      // width: 50vw;
      background-color: red;

      text-align: center;
      position: relative;
      z-index: 9;
      color: #fcfcfe;
      font-family: "Epilogue", sans-serif;
      font-weight: 700;
      font-size: 75px;
    }

    .link {
      color: #ff813b;
      font-weight: 600;
      text-decoration: none;
    }

    .form {
      background-color: #041b32;
      display: flex;
      flex-direction: column;
      width: 55vw;
      border-radius: 10px;
      height: auto;
      margin-top: -2rem;
      position: relative;
      z-index: 9;
    }

    .title {
      width: 55vw;
      font-style: normal;
      font-family: "Epilogue";
      font-weight: 400;
      font-size: 20px;
      line-height: 10px;
      color: #f1f2f6;
      padding-top: 2rem;
      text-align: center;
    }

    .formSub {
      width: 55vw;
      text-align: center;
      padding-top: 2em;
      padding-bottom: 2em;
    }

    .email-input {
      width: 27vw;
      height: 51px;
      background: #404b57;
      border-radius: 8px;
      border: none;
      color: #b5b7ba;
      padding-left: 1em;
      font-size: 16px;
      font-family: "Epilogue";
      font-style: normal;
    }
    .email-input:focus {
      outline: none !important;
    }
    ::placeholder {
      color: #b9b6b6;
      padding-left: 1em;
      // font-family: "Istok Web";
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 29px;
    }

    .submit-btn {
      margin-left: 10px;
      width: 174px;
      height: 51px;
      background: #ff813c;
      border-radius: 8px;
      border: none;
      //text
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 32px;
      color: #f3efef;
    }

    .submit-btn:hover {
      cursor: pointer;
      //como era lo del tiempo de animacion?
      background: #f88f22;
    }

    .subtitle {
      margin-top: 1.2em;
      font-size: 11px;
      text-align: left;
      margin-left: 3.8rem;
      font-weight: 200;
      color: #b5b7ba;
    }
  }
}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {
  .sub {
    width: 100%;
    height: 100vh;

    margin-top: 0px;
    // background-image: url("../../public/assets/viajeAvion.png");
    // filter: brightness(50%);
    background-repeat: no-repeat;
    background-size: 100%;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    .logo-container {
      margin-top: 18rem;
      margin-bottom: 6rem;
    }

    .photo {
      // padding-top: 120px;
      width: 100vw;
      height: 100vh;
      position: absolute;
      top: 0px;
      left: 0px;
      z-index: 1;

      .img {
        top: 0;
        object-fit: cover;
        width: 100vw;
        height: 100vh;
      }
    }

    .logotipo {
      // image-rendering: optimizeQuality;
      // object-fit: cover;

      // height: 30vh; //70
      // width: 50vw;
      position: relative;
      z-index: 9;
      color: #fcfcfe;
      font-family: "Epilogue", sans-serif;
      font-weight: 700;
      font-size: 90px;
    }

    .link {
      color: #ff813b;
      font-weight: 600;
      text-decoration: none;
    }

    .form {
      background-color: #041b32;
      display: flex;
      flex-direction: column;
      width: 35vw;
      border-radius: 10px;
      height: auto;
      margin-top: 2rem;
      position: relative;
      z-index: 9;
    }

    .title {
      font-style: normal;
      font-family: "Epilogue";
      font-weight: 400;
      font-size: 22px;
      line-height: 10px;
      color: #f1f2f6;
      padding-top: 2rem;
      text-align: center;
    }

    .formSub {
      text-align: center;
      padding-top: 2em;
      padding-bottom: 2em;
    }

    .label-input {
      display: flex;
      margin-left: 3.8rem;
      height: auto;
      color: #b5b7ba;
      font-family: "Epilogue";
      font-style: normal;
      font-weight: 400;
      font-size: 13px;
    }

    .email-input {
      width: 371px;
      height: 51px;
      background: #404b57;
      border-radius: 8px;
      border: none;
      color: #b5b7ba;
      padding-left: 1em;
      font-size: 16px;
      font-family: "Epilogue";
      font-style: normal;
    }

    .email-input:focus {
      outline: none !important;
    }

    ::placeholder {
      color: #b9b6b6;
      // padding-left: 1em;
      // font-family: "Istok Web";
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 29px;
    }

    .submit-btn {
      margin-left: 10px;
      width: 174px;
      height: 51px;
      background: #ff813b;
      border-radius: 8px;
      border: none;
      //text
      font-style: normal;
      font-family: "Epilogue";
      font-weight: 400;
      font-size: 16px;
      color: #f1f2f6;
    }

    .submit-btn:hover {
      cursor: pointer;
      //como era lo del tiempo de animacion?
      background: #ff813b;
      transition: 300ms;
    }

    .subtitle {
      margin-top: 1.2em;
      font-size: 11px;
      text-align: left;
      margin-left: 3.8rem;
      font-weight: 200;
      color: #b5b7ba;
    }
  }
}

//1519 x 865    ------     1201
@media only screen and (min-width: 1200px) and (max-width: 1625px) {
  .sub {
    width: 100%;
    height: 100vh;

    margin-top: 0px;
    // background-image: url("../../public/assets/viajeAvion.png");
    // filter: brightness(50%);
    background-repeat: no-repeat;
    background-size: 100%;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    .logo-container {
      margin-top: 13rem;
      margin-bottom: 3rem;
    }

    .photo {
      // padding-top: 120px;
      width: 100vw;
      height: 100vh;
      position: absolute;
      top: 0px;
      left: 0px;
      z-index: 1;

      .img {
        object-fit: cover;
        width: 100vw;
        height: 100vh;
      }
    }

    .logotipo {
      position: relative;
      z-index: 9;
      color: #fcfcfe;
      font-family: "Epilogue", sans-serif;
      font-weight: 700;
      font-size: 90px;
      margin-bottom: 0px;
    }

    .link {
      color: #ff813b;
      font-weight: 600;
      text-decoration: none;
    }

    .form {
      background-color: #041b32;
      display: flex;
      flex-direction: column;
      width: 55vw;
      border-radius: 10px;
      height: auto;
      margin-top: 2rem;
      position: relative;
      z-index: 9;
    }

    .title {
      font-style: normal;
      font-family: "Epilogue";
      font-weight: 400;
      font-size: 22px;
      line-height: 10px;
      color: #f1f2f6;
      padding-top: 2rem;
      text-align: center;
    }

    .formSub {
      text-align: center;
      padding-top: 2em;
      padding-bottom: 2em;
    }

    .label-input {
      display: flex;
      margin-left: 3.8rem;
      height: auto;
      color: #b5b7ba;
      font-family: "Epilogue";
      font-style: normal;
      font-weight: 400;
      font-size: 13px;
    }

    .email-input {
      width: 371px;
      height: 51px;
      background: #404b57;
      border-radius: 8px;
      border: none;
      color: #b5b7ba;
      padding-left: 1em;
      font-size: 16px;
      font-family: "Epilogue";
      font-style: normal;
    }

    .email-input:focus {
      outline: none !important;
    }

    ::placeholder {
      color: #b9b6b6;
      // padding-left: 1em;
      // font-family: "Istok Web";
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 29px;
    }

    .submit-btn {
      margin-left: 10px;
      width: 174px;
      height: 51px;
      background: #ff813b;
      border-radius: 8px;
      border: none;
      //text
      font-style: normal;
      font-family: "Epilogue";
      font-weight: 400;
      font-size: 16px;
      color: #f1f2f6;
    }

    .submit-btn:hover {
      cursor: pointer;
      //como era lo del tiempo de animacion?
      background: #ff813b;
      transition: 300ms;
    }

    .subtitle {
      margin-top: 1.2em;
      font-size: 11px;
      text-align: left;
      margin-left: 3.8rem;
      font-weight: 200;
      color: #b5b7ba;
    }
  }
}

//998 - 768
@media only screen and (min-width: 768px) and (max-width: 990px) {
  .sub {
    width: 100%;
    height: 100vh;

    margin-top: 0px;
    // background-image: url("../../public/assets/viajeAvion.png");
    // filter: brightness(50%);
    background-repeat: no-repeat;
    background-size: 100%;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    .logo-container {
      margin-top: 18rem;
      margin-bottom: 6rem;
    }

    .photo {
      // padding-top: 120px;
      width: 100vw;
      height: 100vh;
      position: absolute;
      top: 0px;
      left: 0px;
      z-index: 1;

      .img {
        object-fit: cover;
        width: 100vw;
        height: 100vh;
      }
    }

    .logotipo {
      position: relative;
      z-index: 9;
      color: #fcfcfe;
      font-family: "Epilogue", sans-serif;
      font-weight: 700;
      font-size: 60px;
      margin-bottom: 0px;
    }

    .link {
      color: #ff813b;
      font-weight: 600;
      text-decoration: none;
    }

    .form {
      background-color: #041b32;
      display: flex;
      flex-direction: column;
      width: 75vw;
      border-radius: 10px;
      height: auto;
      margin-top: 2rem;
      position: relative;
      z-index: 9;
    }

    .title {
      width: 75vw;
      font-style: normal;
      font-family: "Epilogue";
      font-weight: 400;
      font-size: 22px;
      line-height: 10px;
      color: #f1f2f6;
      padding-top: 2rem;
      text-align: center;
    }

    .formSub {
      width: 75vw;
      text-align: center;
      padding-top: 2em;
      padding-bottom: 2em;
    }

    .label-input {
      display: flex;
      margin-left: 3.8rem;
      height: auto;
      color: #b5b7ba;
      font-family: "Epilogue";
      font-style: normal;
      font-weight: 400;
      font-size: 13px;
    }

    .email-input {
      width: 30vw;
      height: 51px;
      background: #404b57;
      border-radius: 8px;
      border: none;
      color: #b5b7ba;
      padding-left: 1em;
      font-size: 16px;
      font-family: "Epilogue";
      font-style: normal;
    }

    .email-input:focus {
      outline: none !important;
    }

    ::placeholder {
      color: #b9b6b6;
      // padding-left: 1em;
      // font-family: "Istok Web";
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 29px;
    }

    .submit-btn {
      margin-left: 10px;
      width: 20vw;
      height: 51px;
      background: #ff813b;
      border-radius: 8px;
      border: none;
      //text
      font-style: normal;
      font-family: "Epilogue";
      font-weight: 400;
      font-size: 16px;
      color: #f1f2f6;
    }

    .submit-btn:hover {
      cursor: pointer;
      //como era lo del tiempo de animacion?
      background: #ff813b;
      transition: 300ms;
    }

    .subtitle {
      margin-top: 1.2em;
      font-size: 11px;
      text-align: left;
      margin-left: 3.8rem;
      font-weight: 200;
      color: #b5b7ba;
    }
  }
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) and (max-width: 768) {
  .sub {
    width: 100vw;
    height: 100vh;
    min-width: 100vw;
    max-width: 100vw;
    margin-top: 0px;
    background-repeat: no-repeat;
    background-size: 100%;
    background-size: 100%;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    .logo-container {
      margin-top: -20rem;
      margin-bottom: 6rem;
      text-align: center;
    }

    .photo {
      // padding-top: 120px;
      width: 100vw;
      height: 100vh;
      position: absolute;
      top: 0px;
      left: 0px;
      z-index: 1;

      .img {
        top: 0px;
        object-fit: cover;
        width: 100vw;
        height: 100vh;
      }
    }

    .logotipo {
      color: #fcfcfe;
      font-family: "Epilogue", sans-serif;
      font-weight: 700;
      font-size: 50px;
    }

    .form {
      display: flex;
      flex-direction: column;
      width: 80vw;
      border-radius: 8px;
      margin-bottom: 3rem;
    }

    .title {
      font-style: normal;
      font-weight: 400;
      font-size: 23px;
      line-height: 15px;
      color: #ffffff;
      padding-top: 2rem;
      text-align: center;
      width: 80vw;
    }

    .formSub {
      text-align: center;
      padding-top: 1em;
      width: 100vw;
      padding-bottom: 2rem;
      height: 100%;
      width: 80vw;
    }

    .label-input {
      display: flex;
      margin-left: 8.5rem;
      height: auto;
      color: #b5b7ba;
      font-family: "Epilogue";
      font-style: normal;
      font-weight: 400;
      font-size: 13px;
    }

    .email-input {
      width: 371px;
      height: 51px;
      background: rgba(50, 53, 53, 0.83);
      border-radius: 8px;
      border: none;
    }

    ::placeholder {
      color: #b9b6b6;
      padding-left: 1em;
      // font-family: "Istok Web";
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 29px;
    }

    .submit-btn {
      margin-left: 10px;
      width: 174px;
      height: 51px;
      background: #ff813c;
      border-radius: 8px;
      border: none;
      //text
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 32px;
      color: #f3efef;
    }

    .submit-btn:hover {
      cursor: pointer;
      //como era lo del tiempo de animacion?
      background: #f88f22;
    }

    .subtitle {
      margin-top: 1em;
      font-size: 14px;
      font-weight: 200;
      color: #ffffff;
      text-align: center;
    }
  }
}


