.blog-container {
  display: flex;
  align-items: center;
  flex-direction: column;
}

.blog-headline {
  margin-top: 50px;
  height: 315px;
  width: 100vw;
  background-repeat: no-repeat;
  background-size: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.background-mainPhoto-blog {
  width: 100vw;
  height: 315px;
  position: absolute;
  z-index: 1;

  object-fit: cover;

  filter: brightness(70%); // Más oscura
}

.post-title-blog {
  text-align: center;
  color: rgba(255, 255, 255, 0.904);
  position: absolute;
  z-index: 9;
  width: 30vw;
}

.title-blog {
  margin-top: 10rem;
  font-style: normal;
  font-family: "Epilogue";
  color: #041b32;
  font-family: "Epilogue", sans-serif;
  font-weight: 700;
  font-size: 90px;
  padding-top: 2rem;
  text-align: center;
}

.presentation-parr {
  margin-top: 2.5rem;
  font-style: normal;
  font-family: "Epilogue";
  color: #041b32;
  font-family: "Epilogue", sans-serif;
  font-weight: 400;
  font-size: 20px;
  width: 55vw;
  line-height: 30px;
  text-align: justify;
}

.date {
  display: flex;
  align-self: flex-start;
  margin-left: 20px;
  margin-top: 15px;
  margin-bottom: 0;
  font-size: 14px;
}

.caption {
  font-style: italic;
  margin-top: 0.5rem;
}

.blog-main-photo {
  width: 30vw;
  height: 40vh;
  object-fit: cover;
  border-radius: 7px;
}

.blog-h {
  margin-bottom: 1rem;
  margin-top: 0.5rem;
  max-width: 30vw;
  // margin: 1rem;
}

.blog-p {
  margin-top: 1.5rem;
  margin-bottom: 1rem;
  font-size: 12px;
}

.nuestros-blogs {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100vw;
  margin-top: 5rem;
}

.item-card {
  display: flex;
  justify-content: center;
}

.nuestros-h {
  margin-bottom: 1rem;
  color: #041b32;
  font-size: 45px;
}

.line-big {
  height: 5px;
  background-color: #ff813c;
  width: 55vw;
  margin-bottom: 3rem;
  border-radius: 10px;
}

.categories {
  margin-top: 10rem;
}

.card-title {
  width: 90%;
  // color: red;
  text-align: center;

  text-overflow: ellipsis;
  // overflow: hidden;
  // white-space: no-wrap;
}

.layout-container {
  display: flex;
  margin-top: 3.5rem;
}

.mainpost-layout {
  border-radius: 8px;
  width: 550px;
  height: 350px;
}

.mainPost-category {
  font-family: "Epilogue";
  font-weight: 700;
  font-size: 30px;
}

.mainpost-author {
  text-align: right;
  font-size: 13px;
  margin-top: 2rem;
}

.lineMainPost {
  height: 3px;
  width: 550px;
  background-color: #ff813c;
  border-radius: 8px;
  margin-bottom: 0.6rem;
}

.title-layout {
  padding-top: 1rem;
  width: 550px;
  text-align: center;
  line-height: 28px;
  color: #041b32;
  cursor: "pointer";
}

.title-layout:hover {
  color: #ff813c;
  transition: 300ms;
  cursor: pointer;
}

.main {
  margin-top: 1rem;
  margin-left: 3rem;
  margin-left: 0px;
}

.display-articles {
  // background-color: red;
}

.row {
  display: flex;
  flex: row;
  margin: 3rem;
  margin-right: 1rem;
  width: 25vw;
}

.image-article {
  width: 135px;
  height: 135px;
  border-radius: 8px;
  object-fit: cover;
}

.description-display {
  margin-left: 2rem;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;

  .art-title {
    color: #041b32;
    font-size: 17px;
    font-weight: 700;
    margin-top: 0.4rem;
    margin-bottom: 1rem;
    line-height: 20px;
  }

  .art-category {
    font-size: 16px;
    margin-bottom: 0.5rem;
  }

  .art-author {
    font-size: 12px;
  }

  .art-text {
    display: flex;
    padding-bottom: 0.15rem;
    padding-left: 0.25rem;
    color: #ff813c;
  }

  .icon-add {
    margin-top: 0.5rem;
    display: flex;
    align-items: flex-end;
    justify-content: flex-start;
  }

  .icon-add:hover {
    border-radius: 3px;
    padding: 1px;
    transition: 400ms;
  }
}

/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
  .blog-container {
    display: flex;
    align-items: center;
    flex-direction: column;
    width: 100vw;
  }

  .title-blog {
    margin-top: 10rem;
    font-style: normal;
    font-family: "Epilogue";
    color: #041b32;
    font-family: "Epilogue", sans-serif;
    font-weight: 700;
    font-size: 70px;
    padding-top: 2rem;
    text-align: center;
  }

  .presentation-parr {
    margin-top: 2.5rem;
    font-style: normal;
    font-family: "Epilogue";
    color: #041b32;
    font-family: "Epilogue", sans-serif;
    font-weight: 400;
    font-size: 20px;
    width: 80vw;
    line-height: 30px;
    text-align: justify;
  }

  .layout-container {
    display: flex;
    flex-direction: column-reverse;
    align-content: center;
    justify-content: center;
    margin-top: 3.5rem;
    width: 90vw;
  }

  .mainpost-layout {
    border-radius: 8px;
    width: 90vw;
    height: 25vh;
    margin: 0 auto;
  }

  .mainPost-category {
    font-family: "Epilogue";
    font-weight: 700;
    font-size: 30px;
  }

  .mainpost-author {
    text-align: right;
    font-size: 13px;
    margin-top: 2rem;
  }

  .lineMainPost {
    height: 3px;
    width: 90vw;
    background-color: #ff813c;
    border-radius: 8px;
    margin-bottom: 0.6rem;
  }

  .title-layout {
    padding-top: 1rem;
    font-size: 20px;
    width: 90vw;
    margin: 0 auto;
    text-align: center;
    line-height: 28px;
  }

  .main {
    // margin: 0 auto;
    width: 90vw;
    margin: 0 auto;
  }

  .caption {
    font-style: italic;
    margin-top: 0.8rem;
  }

  .blog-main-photo {
    width: 60vw;
  }

  .blog-h {
    margin-bottom: 1rem;
    margin-top: 1rem;
    max-width: 60vw;
    // margin: 1rem;
  }

  .blog-p {
    margin-top: 1.5rem;
    margin-bottom: 1.3rem;
    font-size: 12px;
  }

  .nuestros-blogs {
    display: flex;
    flex-direction: column;
    // align-items: center;
    // justify-content: center;

    width: 100vw;
    margin-top: 5rem;
    align-content: center;
    align-self: center;
    align-items: center;
    justify-items: center;
    justify-content: center;
  }

  .item-card {
    display: flex;
  }

  .categories {
    margin-top: 10rem;
  }

  .card-title {
    width: auto;
    text-align: center;
    text-overflow: ellipsis;
  }

  .nuestros-h {
    width: 80vw;
    text-align: center;
    font-size: 30px;
  }

  //display
  .display-articles {
    margin: 0 auto;
  }

  .row {
    display: flex;
    flex-direction: row;
    justify-content: left;
    margin: 0 auto;
    margin-top: 3rem;
    width: 90vw;
  }

  .image-article {
    width: 100px;
    height: 100px;
    border-radius: 8px;
    object-fit: cover;
  }

  .description-display {
    margin-left: 2rem;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;

    .art-title {
      color: #041b32;
      font-size: 17px;
      font-weight: 700;
      margin-top: 0.4rem;
      margin-bottom: 1rem;
      line-height: 20px;
    }

    .art-category {
      font-size: 16px;
      margin-bottom: 0.5rem;
    }

    .art-author {
      font-size: 12px;
    }

    .art-text {
      display: flex;
      padding-bottom: 0.15rem;
      padding-left: 0.25rem;
      color: #ff813c;
    }

    .icon-add {
      margin-top: 0.5rem;
      display: flex;
      align-items: flex-end;
      justify-content: flex-start;
    }

    .icon-add:hover {
      border-radius: 3px;
      padding: 1px;
      transition: 400ms;
    }
  }
}

//galaxy fold
@media only screen and (max-width: 300px) {
  .blog-container {
    display: flex;
    align-items: center;
    flex-direction: column;
  }

  .blog-headline {
    height: 30vh;
    width: 100vw;
    background-repeat: no-repeat;
    background-size: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .background-mainPhoto-blog {
    width: 100vw;
    height: 30vh;
    position: absolute;
    z-index: 1;

    object-fit: cover;

    filter: brightness(70%); // Más oscura
  }

  .post-title-blog {
    text-align: center;
    color: rgba(255, 255, 255, 0.904);
    position: absolute;
    z-index: 9;
    width: 50vw;
  }

  .presentation-blog {
    margin-top: 5rem;
    width: 80vw;
    height: auto;
    background: #ffffff;
    border-radius: 5px;
    box-shadow: 0px 5px 6px 3px rgba(0, 0, 0, 0.25);
    text-align: center;
    color: #808080;
    display: flex;
    align-items: center;
    flex-direction: column;
  }

  .date {
    display: flex;
    align-self: flex-start;
    margin-left: 10px;
    margin-top: 10px;
    margin-bottom: 0;
    font-size: 11px;
  }

  .caption {
    font-style: italic;
    margin-top: 0.8rem;
  }

  .blog-main-photo {
    width: 60vw;
  }

  .blog-h {
    margin-bottom: 1rem;
    margin-top: 1rem;
    max-width: 60vw;
    // margin: 1rem;
  }

  .blog-p {
    margin-top: 1.5rem;
    margin-bottom: 1.3rem;
    font-size: 12px;
  }

  .nuestros-blogs {
    display: flex;
    flex-direction: column;
    width: 100vw;
    margin-top: 5rem;
    align-content: center;
    align-self: center;
    align-items: center;
    justify-items: center;
    justify-content: center;
  }

  .grid {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin: 0;
    padding: 0;
  }

  .item-card {
    display: flex;
  }

  .categories {
    margin-top: 10rem;
  }

  .card-title {
    width: auto;
    text-align: center;
    text-overflow: ellipsis;
  }
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) and (max-width: 1200px) {
  .blog-headline {
    margin-top: 50px;
    height: 40vh;
    width: 100vw;
    background-repeat: no-repeat;
    background-size: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .background-mainPhoto-blog {
    width: 100vw;
    height: 40vh;
    position: absolute;
    z-index: 1;

    object-fit: cover;

    filter: brightness(70%); // Más oscura
  }

  .post-title-blog {
    text-align: center;
    color: rgba(255, 255, 255, 0.904);
    position: absolute;
    z-index: 9;
    width: 30vw;
  }

  .date {
    display: flex;
    align-self: flex-start;
    margin-left: 20px;
    margin-top: 10px;
    margin-bottom: 0;
    font-size: 14px;
  }

  .caption {
    font-style: italic;
    margin-top: 0.5rem;
  }

  .blog-main-photo {
    width: 45vw;
  }

  .blog-h {
    margin-bottom: 1rem;
    margin-top: 0.5rem;
    max-width: 45vw;
  }

  .blog-p {
    margin-top: 1.5rem;
    margin-bottom: 1rem;
    font-size: 12px;
  }

  .nuestros-blogs {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 70vw;
    margin-top: 5rem;
  }

  .item-card {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 auto;
  }

  .grid {
  }

  .categories {
    margin-top: 10rem;
  }

  .card-title {
    width: auto;
    text-overflow: ellipsis;
  }
}

//tablets grandes
@media only screen and (min-width: 768px) and (max-width: 990px) {
  .blog-container {
    display: flex;
    align-items: center;
    flex-direction: column;
  }

  .blog-headline {
    margin-top: 50px;
    height: 35vh;
    width: 100vw;
    background-repeat: no-repeat;
    background-size: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .background-mainPhoto-blog {
    width: 100vw;
    height: 35vh;
    position: absolute;
    z-index: 1;

    object-fit: cover;

    filter: brightness(70%); // Más oscura
  }

  .post-title-blog {
    text-align: center;
    color: rgba(255, 255, 255, 0.904);
    position: absolute;
    z-index: 9;
    width: 30vw;
  }

  .presentation-blog {
    margin-top: 5rem;
    width: 80vw;
    height: auto;
    background: #ffffff;
    border-radius: 5px;
    box-shadow: 0px 5px 6px 3px rgba(0, 0, 0, 0.25);
    text-align: center;
    color: #808080;
    display: flex;
    align-items: center;
    flex-direction: column;
  }

  .date {
    display: flex;
    align-self: flex-start;
    margin-left: 20px;
    margin-top: 10px;
    margin-bottom: 0;
    font-size: 14px;
  }

  .caption {
    font-style: italic;
    margin-top: 0.5rem;
  }

  .blog-main-photo {
    width: 50vw;
  }

  .blog-h {
    margin-bottom: 1rem;
    margin-top: 0.5rem;
    max-width: 50vw;
  }

  .blog-p {
    margin-top: 1.5rem;
    margin-bottom: 1rem;
    font-size: 12px;
  }

  .nuestros-blogs {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 80vw;
    margin-top: 5rem;
  }

  .item-card {
    display: flex;
    justify-content: center;
  }

  .categories {
    margin-top: 10rem;
  }

  .card-title {
    width: 85%;
    text-align: center;
    text-overflow: ellipsis;
  }
}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {
}
