.container-facts {
  height: 100vh;
}

.containerFacts {
  margin-top: 15rem;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.rectangle {
  border: 6px solid #ff9357;
  border-radius: 16px;
}

.title-facts {
  font-family: "Epilogue";
  font-style: normal;
  font-weight: 700;
  font-size: 80px;
  line-height: 53px;
  color: #041b32;
  padding: 3.5rem;
}

.icons {
  margin-top: 5rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

// .icon1 {
//   width: 10vw;
//   height: 10vh;
// }

// .icon3 {
// }

.icon2 {
  width: auto;
  height: 30vh;
  margin: 2rem;
}

.icon-container {
  // margin-left: 3rem;
  // margin-right: 3rem;
  text-align: center;
}

.subtitle-icon {
  font-size: 25px;
  font-weight: 700;
  line-height: 35px;
  color: #041b32;
}

/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
  .container-facts {
    height: 120vh;
    max-width: 100vw;
    margin-bottom: 25rem;
  }

  .containerFacts {
    margin-top: 10rem;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    margin-bottom: 10rem;
  }

  .icons {
    margin-top: 5rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    // margin: 0 auto;
  }

  .icon-container {
    margin: 1rem;
  }

  // .icon1 {
  //   width: 50vw;
  //   background-size: cover;
  //   height: 24vh;
  //   margin: 0 auto;
  // }

  .icon2 {
    width: auto;
    height: 20vh;
    margin: 0rem;
  }

  // .icon3 {
  //   width: 50vw;
  //   background-size: cover;
  //   height: 24vh;
  //   margin: 0 auto;

  // }

  .title-facts {
    font-family: "Epilogue";
    font-style: normal;
    font-weight: 700;
    font-size: 23px;
    line-height: 53px;
    color: #041b32;
    padding: 3rem;
  }
}

//galaxy fold
@media only screen and (max-width: 300px) {
  .container-facts {
    max-height: 100vh;
    max-width: 100vw;
    margin-bottom: 25rem;
  }

  .containerFacts {
    margin-top: 10rem;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }

  .icons {
    margin-top: 2rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    color: #656565;
  }

  .icon-container {
    margin: 3rem;
    text-align: center;
  }

  // .icon1 {
  //   width: 50vw;
  //   background-size: cover;
  //   height: 22vh;
  // }

  // .icon2 {
  //   width: 50vw;
  //   background-size: cover;
  //   height: 22vh;
  // }

  // .icon3 {
  //   width: 50vw;
  //   background-size: cover;
  //   height: 22vh;
  // }

  .title-facts {
    text-align: center;
    font-family: "Jacques Francois";
    font-style: normal;
    font-weight: 400;
    font-size: 40px;
    line-height: 53px;
    color: #656565;
  }
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) and (max-width: 1200px) {
  .container-facts {
    height: 80vh;
    width: 100vw;
  }

  .containerFacts {
    margin-top: 5rem;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }

  // .icons {
  //   margin-top: 12rem;
  //   display: flex;
  //   flex-direction: row;
  //   align-items: center;
  //   justify-content: center;
  //   color: #656565;
  // }

  .icon-container {
    // margin-left: 6rem;
    // margin-right: 6rem;
    right: 0;
    left: 0;
    padding-left: 3rem;
    padding-right: 3rem;
    margin: 0 auto;
    text-align: center;
  }

  // .icon1{
  //   width: 45vw;
  //   background-size: cover;
  //   height: 20vh;
  //   background-image: url("../../public/assets/facts-icons/Iconos-03.png");
  // }

  // .icon2{
  //   width: 45vw;
  //   background-size: cover;
  //   height: 20vh;
  //   background-image: url("../../public/assets/facts-icons/Iconos-04.png");
  // }

  // .icon3{
  //   width: 45vw;
  //   background-size: cover;
  //   height: 20vh;
  //   background-image: url("../../public/assets/facts-icons/Iconos-05.png");
  // }
}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) and (max-width: 990px) {
  .container-facts {
    height: 110vh;
    width: 100vw;
  }

  .containerFacts {
    margin-top: 15rem;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    text-align: center;
  }

  .title-facts {
    font-family: "Epilogue";
    font-style: normal;
    font-weight: 700;
    font-size: 60px;
    line-height: 80px;
    color: #041b32;
    padding: 2rem;
  }

  .icon2{
    margin: 0;
  }

  .icons {
    margin-top: 5rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .icon-container {
    margin: auto;
  }

  .rectangle {
    margin-top: 10rem;
    border: 6px solid #ff9357;
    border-radius: 16px;
  }


}

// 600- 768
@media only screen and (min-width: 600px) and (max-width: 768px) {

  .container-facts {
    height: 110vh;
    width: 100vw;
  }

  .containerFacts {
    margin-top: 15rem;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    text-align: center;
  }

  .title-facts {
    font-family: "Epilogue";
    font-style: normal;
    font-weight: 700;
    font-size: 60px;
    line-height: 80px;
    color: #041b32;
    padding: 2rem;
  }

  .icon2{
    margin: 0;
  }

  .icons {
    margin-top: 5rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .icon-container {
    margin: auto;
  }

  .rectangle {
    margin-top: 10rem;
    border: 6px solid #ff9357;
    border-radius: 16px;
  }

}


/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .container-facts {
    height: 100vh;
    margin-top: 15rem;
  }
  .containerFacts {
    margin-top: 20rem;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }

  .containerFacts {
    margin-top: 4rem;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    text-align: center;
  }

  .icons {
    margin-top: 4rem;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    color: #656565;
  }

  // .icon1 {
  //   width: 20vw;
  //   background-size: cover;
  //   height: 30vh;
  //   background-repeat: no-repeat;
  // }

  // .icon2 {
  //   width: 20vw;
  //   background-size: cover;
  //   height: 30vh;
  // }

  // .icon3 {
  //   width: 21vw;
  //   background-size: cover;
  //   height: 30vh;
  // }
}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {
}
