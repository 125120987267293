.headline {
  margin-top: 50px;

  height: 315px;
  width: 100%;
  background-repeat: no-repeat;
  background-size: 100%;
  background-image: url("../../public/assets/lago-di-braies-italia_1d38ca01_1254x836.jpg");
}

.title-PP {
  padding-top: 9rem;
  text-align: center;
  color: rgba(255, 255, 255, 0.904);
}

.text-PP {
  width: 80vw;
  margin: 0 auto;
  // display: flex;
  // align-items: center;
  // justify-content: center;
}

.subtitle-PP{
    color: #f88f22;
    // margin-left: 20rem;
    // margin-right: 20rem;
    margin-bottom: 1.5rem;
    margin-top: 2rem;

}

.par-PP {
  margin: 0 auto;
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 29px;

  color: #808080;
}


@media only screen and (max-width: 600px) {

  .par-PP {
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: center;
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    line-height: 29px;
  
    color: #808080;
  }

}

@media only screen and (min-width: 600px) and (max-width: 1200px) {
}

@media only screen and (min-width: 992px) and (max-width:1200px) {
}

