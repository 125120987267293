.post-container {
  display: flex;
  align-items: center;
  flex-direction: column;
}

.post-headline {
  margin-top: 80px;
  height: 35vh;
  width: 100%;
  background-repeat: no-repeat;
  background-size: 100%;
}

.background-mainPhoto {
  width: 100vw;
  height: 35vh;
  position: absolute;
  z-index: 1;

  object-fit: cover;
  filter: brightness(63%) blur(1.1px) opacity(98%); 
}

.post-title {
  display: flex;
  font-family: 'Epilogue';
  justify-content: center;
  margin: 0 auto;
  height: 35vh;
  align-items: center;
  color: #f1f2f6;
  position: absolute;
  z-index: 9;
  width: 100vw;
  font-size: 40px;
}

.show-sections {
  margin-top: 3rem;
}

//TEMPLATE MANAGEMENT

//READY FALTA RESPONSIVENESS
.template-1 {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 50vw;
  margin: 0 auto;
  // margin-top: 2rem;
  // margin-bottom: 2rem;

  .post-subtitle {
    width: 250vw;
    align-self: flex-start;
    // background-color: red;
    // font-size: 34px;
    // margin-bottom: 2rem;
    color: #4e4d4d;
  }

  .slide-image {
    // display: flex;
    width: 27vw;
  }

  .article-caption {
    margin-left: 1.5rem;
    font-style: italic;
    font-size: 13.5px;
    color: #4e4d4d;
  }

  img {
  }

  .post-image {
    margin-left: 1.5rem;
  }

  .post-parr {
    margin-top: 0;
    margin-bottom: 0;
    text-align: justify;
    align-self: flex-start;
  }

}

.template-2 {
  
  width: 70vw;
  height: fit-content;
  display: flex;
  flex-direction: column;
  align-items: center;
  // margin-bottom: 2rem;
  margin-top: 0px;


  .article-caption {
    font-style: italic;
    font-size: 13.5px;
    color: #4e4d4d;
  }
  
  .post-image {
    margin-top: 3rem;
    width: 50vw;
    max-height: 60vh;
    object-fit: cover;
    border-radius: 12px;
  }

  .slide-image {
    width: 50vw;
    text-align: center;
    margin-bottom: 2rem;
  }

  //OJO
  .image-caption {
    margin-left: 4.9rem;
  }

  .post-subtitle {
    margin-top: 4rem;
    margin-bottom: -2.5rem;
    order: 0;
    font-size: 40px;
    text-align: center;
    font-family: 'Epilogue';
    color: #041b32;
  }

  .post-parr {
    margin-top: 0px;
    text-align: justify;
    width: 50vw;
    line-height: 1.7rem;
    margin-bottom: 0rem;

  }

  p {
    order: 2;
  }
}

.template-3 {
  margin: 0 auto;
  text-align: center;
  width: 50vw;
  color:#041b32;
  margin-bottom: 0px;

  .article-caption {
    font-style: italic;
    font-size: 13.5px;
    color: #4e4d4d;
  }

  .post-subtitle {
    margin-top: 4rem;
    margin-bottom: -2.5rem;
    order: 0;
    font-size: 40px;
    text-align: center;
    font-family: 'Epilogue';
    color: #041b32;
  }

  .post-parr {
    line-height: 1.7rem;
    text-align: justify;
    width: 50vw;
    margin-top: 2.5rem;
    margin-bottom: 2rem;
  }

  .slide-image {
    width: 50vw;
  }

  .post-image {
    width: 50vw;
    max-height: 60vh;
    object-fit: cover;
    border-radius: 12px;
  }

  p {
    order: 0;
    flex: 1;
  }
}


img {
  width: 25vw;
}

.slide-image {
  position: relative;
  margin: 0 auto;
  width: 700px;
}

.image-caption {
  position: absolute;
  bottom: 2.5em;
  width: 25vw;
  // padding-top: .5em;
  background-color: rgba(0, 0, 0, 0.6);
  color: #ebebeb;
  left: 2em;
  height: 34px;
  overflow: hidden;
  transition: all 0.4s ease-out;
}

.image-caption:before {
  content: "+";
  display: block;
  position: absolute;
  right: 0;
  top: 0;
  color: #fff;
  height: 40px;
  width: 35px;
  text-align: center;
  line-height: 40px;
  font-size: 2em;
  font-weight: bold;
  transition: all 0.4s ease-out;
}

.image-caption:hover:before {
  content: "-";
  top: 0;
}

.image-caption:hover {
  height: 70px;
}

.image-caption {
  margin: 0;
  padding: 0 4em 0 1em;
  font-style: italic;
}


/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {

  .post-container {
    display: flex;
    align-items: center;
    flex-direction: column;
  }
  
  .post-headline {
    margin-top: 50px;
    height: 35vh;
    width: 100%;
    background-repeat: no-repeat;
    background-size: 100%;
  }
  
  .background-mainPhoto {
    width: 100vw;
    height: 35vh;
    position: absolute;
    z-index: 1;
  
    object-fit: cover;
    filter: brightness(63%) blur(1.1px) opacity(98%); 
  }
  
  .post-title {
    // padding-top: 9rem;
    display: flex;
    justify-content: center;
    // margin: 0 auto;
    height: 35vh;
    align-items: center;
  
    text-align: center;
    color: rgba(255, 255, 255, 0.904);
    position: absolute;
    z-index: 9;
    width: 100vw;
    font-size: 22px;
  }

  //TEMPLATE MANAGEMENT

.template-1 {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100vw;
  margin: 0 auto;
  text-align: center;
  margin-top: 2rem;
  margin-bottom: 2rem;

  .post-subtitle {
    width: 250vw;
    align-self: flex-start;
    // background-color: red;
    // font-size: 34px;
    margin-bottom: 2rem;
    color: #4e4d4d;
  }

  .slide-image {
    // display: flex;
    width: 27vw;
  }

  .article-caption {
    margin-left: 1.5rem;
    font-style: italic;
    font-size: 13.5px;
    color: #4e4d4d;
  }

  img {
  }

  .post-image {
    margin-left: 1.5rem;
  }

  .post-parr {
    margin: 0 auto;
    text-align: justify;
    width: 80vw;
    font-size: 13.5px;
  }

  p {
    // color: red;
    // margin: 2rem;
  }
}

.template-2 {
  // margin: 0 auto;
  // text-align: center;
  width: 100vw;
  display: flex;
  flex-direction: column;
  margin-top: 2rem;
  margin-bottom: 2rem;
  align-items: center;

  .article-caption {
    margin-left: 0rem;
    text-align: left;
    font-style: italic;
    font-size: 10px;
    color: #4e4d4d;
  }

  h3 {
  }

  .post-image {
    width: 80vw;
  }

  .slide-image {
    width: 80vw;
    text-align: center;
    margin-bottom: 2rem;
  }

  //OJO
  .image-caption {
    // margin-left: 4.9rem;
  }

  .post-subtitle {
    padding-top: 8rem;
    width: 80vw;
    margin: 0 auto;
    // background-color: red;
    // height: 10vh;
    font-size: 18px;
    color: #4e4d4d;

    // text-decoration: underline #4FA2B5 2px 10px;

    // background-color: red;
  }

  .post-parr {
    text-align: justify;
    width: 80vw;
    font-size: 13.5px;
  }

  caption {
  }

  p {
    order: 2;
    // color: green;
    // text-align: left;
  }
}

.template-3 {
  margin: 0 auto;
  text-align: center;
  width: 100vw;
  margin-top: 2rem;
  margin-bottom: 2rem;

  .article-caption {
    margin-left: 0rem;
    font-style: italic;
    font-size: 10px;
    color: #4e4d4d;
  }

  // display: flex;

  .post-subtitle {
    padding-top: 8rem;
    width: 80vw;
    margin: 0 auto;
    // background-color: red;
    // height: 10vh;
    font-size: 18px;
    color: #4e4d4d;
  }

  .post-parr {
    margin: 0 auto;
    text-align: justify;
    width: 80vw;
    font-size: 13.5px;
    margin-bottom: 1.2rem;
  }

  .slide-image {
    width: 50vw;
  }

  .post-image {
    width: 50vw;
    max-height: 60vh;
    object-fit: contain;
  }

  // img {
  //   position: absolute;
  //   z-index: 9;
  //   top: -270px;
  // }

  // .image-caption {
  //   z-index: 10;
  //   left: 350px;
  //   top: -35px;
  // }

  p {
    order: 0;
    flex: 1;
  }
}
  



}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {}