.card {
  border-radius: 5px;
  width: 331px;
  height: 480px;
  display: flex;
  flex-direction: column;
  align-items: center;
  background: #fefefe;
  box-shadow: 3px 4px 4px 1px rgba(0, 0, 0, 0.05);
  border-radius: 6px;
  margin: 0 auto;
  position: relative;
}

.card:hover {
  transition: 500ms;
  box-shadow: 6px 6px 6px 2px rgba(0, 0, 0, 0.05);
}

.image {
  margin-bottom: 1rem;
  width: 300px;
  height: 300px;
  margin-top: 1.3rem;
  border-radius: 8px;
  object-fit: cover;
}

.card-title {
  width: 100%;
  font-size: 20px;
  color: #041b32;
  text-decoration: none;
  font-family: "Epilogue";
  font-weight: 600;
  font-size: 21px;
}

.leer-mas {
  background-color: transparent;
  border: 2px solid #ff813b;
  border-radius: 52px;
  color: #ff813b;
  font-size: 18px;
  padding: 5px 15px 5px 15px;
  width: 50%;
  position: absolute;
  bottom: 15px;
}
.leer-mas:hover {
  transition: 500ms;
  background-color: #ff813b;
  border: 2px solid #ff813b;
  color: #fefefe;
  cursor: pointer;
}

/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
  .card {
    border-radius: 5px;
    width: 70vw;
    height: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    background: rgba(251, 251, 251, 0.24);
    box-shadow: 0px 4px 12px 3px rgba(155, 155, 155, 0.12);
    border-radius: 8px;
    position: relative;
  }

  .card:hover {
    transition: 400ms;
    background-color: rgba(238, 238, 238, 0.699);
  }

  .image {
    margin-bottom: 1rem;
    width: 200px;
    height: 200px;
    margin-top: 2rem;
    object-fit: cover;
  }

  .card-title {
    width: 50vw;
    max-width: 50vw;
    font-size: 15px;
    margin-top: 0.2rem;
    margin-bottom: 0.3rem;
    text-decoration: none;
  }

  .leer-mas {
    background-color: transparent;
    border: 2px solid #ff813b;
    border-radius: 52px;
    color: #ff813b;
    font-size: 18px;
    padding: 5px 15px 5px 15px;
    width: 60%;
    margin-top: 2rem;
    position: inherit;
  }
  .leer-mas:hover {
    transition: 500ms;
    background-color: #ff813b;
    border: 2px solid #ff813b;
    color: #fefefe;
    cursor: pointer;
  }
}

//galaxy fold
@media only screen and (max-width: 300px) {
  .card {
    border-radius: 5px;
    width: auto;
    height: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    background: rgba(251, 251, 251, 0.24);
    box-shadow: 0px 4px 12px 3px rgba(155, 155, 155, 0.12);
    border-radius: 8px;
  }

  .card:hover {
    transition: 400ms;
    background-color: rgba(238, 238, 238, 0.699);
  }

  .image {
    margin-bottom: 1rem;
    width: 170px;
    height: 170px;
    margin-top: 2rem;
    object-fit: cover;
  }

  .card-title {
    font-size: 16px;
    margin-top: 1rem;
    margin-bottom: 2rem;
    max-width: 70vw;
    color: rgba(0, 0, 0, 0.658);
    text-decoration: none;
  }
}

//
@media only screen and (min-width: 600px) and (max-width: 768px) {
  .card {
    border-radius: 5px;
    width: 30vw;
    height: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    background: rgba(251, 251, 251, 0.24);
    box-shadow: 0px 4px 12px 3px rgba(155, 155, 155, 0.12);
    border-radius: 8px;
    position: relative;
  }

  .card:hover {
    transition: 400ms;
    background-color: rgba(238, 238, 238, 0.699);
  }

  .image {
    margin-bottom: 1rem;
    width: 250px;
    height: 250px;
    margin-top: 2rem;
    object-fit: cover;
  }

  .card-title {
    max-width: 20vw;
    font-size: 15px;
    margin-top: 0.2rem;
    margin-bottom: 0.3rem;
    text-decoration: none;
  }

  .leer-mas {
    background-color: transparent;
    border: 2px solid #ff813b;
    border-radius: 52px;
    color: #ff813b;
    font-size: 18px;
    padding: 5px 15px 5px 15px;
    width: 60%;
    margin-top: 2rem;
    position: inherit;
  }
  .leer-mas:hover {
    transition: 500ms;
    background-color: #ff813b;
    border: 2px solid #ff813b;
    color: #fefefe;
    cursor: pointer;
  }
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) and (max-width: 1200px) {
  .card {
    border-radius: 5px;
    width: auto;
    // width: 14vw;
    height: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    background: rgba(251, 251, 251, 0.24);
    box-shadow: 0px 4px 12px 3px rgba(155, 155, 155, 0.12);
    border-radius: 8px;
  }

  .card:hover {
    transition: 400ms;
    background-color: rgba(238, 238, 238, 0.699);
  }


  .card-title {
    width: 25vw;
    font-size: 16px;
    margin-top: 1rem;
    margin-bottom: 2rem;
    max-width: 60vw;
    text-decoration: none;
  }


}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) and (max-width: 990px) {
  .card {
    border-radius: 5px;
    width: 80vw;
    height: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    background: #fefefe;
    box-shadow: 3px 4px 4px 1px rgba(0, 0, 0, 0.05);
    border-radius: 6px;
    margin: 0 auto;
    position: relative;
  }

  .card:hover {
    transition: 500ms;
    box-shadow: 6px 6px 6px 2px rgba(0, 0, 0, 0.05);
  }

  .image {
    margin-bottom: 1rem;
    width: 60vw;
    height: 30vh;
    margin-top: 1.2rem;
    border-radius: 8px;
    object-fit: cover;
  }

  .card-title {
    max-width: 60vw;
    height: 10vh;
    font-size: 20px;
    color: #041b32;
    text-decoration: none;
    font-family: "Epilogue";
    font-weight: 600;
    font-size: 21px;
    line-height: 25px;
  }

  .leer-mas {
    background-color: transparent;
    border: 2px solid #ff813b;
    border-radius: 52px;
    color: #ff813b;
    font-size: 18px;
    padding: 5px 15px 5px 15px;
    width: 35%;
    position: absolute;
    bottom: 25px;
  }
  .leer-mas:hover {
    transition: 500ms;
    background-color: #ff813b;
    border: 2px solid #ff813b;
    color: #fefefe;
    cursor: pointer;
  }
}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) and (max-width: 1200px) {
  .card {
    border-radius: 5px;
    width: 30vw;
    height: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    background: rgba(251, 251, 251, 0.24);
    box-shadow: 0px 4px 12px 3px rgba(155, 155, 155, 0.12);
    border-radius: 8px;
    position: relative;
  }

  .card:hover {
    transition: 400ms;
    background-color: rgba(238, 238, 238, 0.699);
  }

  .image {
    margin-bottom: 1rem;
    width: 250px;
    height: 250px;
    margin-top: 2rem;
    object-fit: cover;
  }

  .card-title {
    max-width: 25vw;
    font-size: 15px;
    margin-top: 0.2rem;
    margin-bottom: 0.3rem;
    text-decoration: none;
  }

  .leer-mas {
    background-color: transparent;
    border: 2px solid #ff813b;
    border-radius: 52px;
    color: #ff813b;
    font-size: 18px;
    padding: 5px 15px 5px 15px;
    width: 60%;
    margin-top: 2rem;
    position: inherit;
  }
  .leer-mas:hover {
    transition: 500ms;
    background-color: #ff813b;
    border: 2px solid #ff813b;
    color: #fefefe;
    cursor: pointer;
  }
}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {
}
