/**
  * four-dots
  *
  * @author jh3y
*/
.four-dots:before {
    display: block;
    height: 5px;
    width: 5px;
    content: '';
    -webkit-animation: spin 1s infinite;
            animation: spin 1s infinite;
    border-radius: 100%;
    -webkit-box-shadow: 20px 0 0 5px #f88f22, 0 20px 0 5px #f88f22, 0 -20px 0 5px #f88f22, -20px 0 0 5px #f88f22;
            box-shadow: 20px 0 0 5px #f88f22, 0 20px 0 5px #f88f22, 0 -20px 0 5px #f88f22, -20px 0 0 5px #f88f22; }
  
  @-webkit-keyframes spin {
    to {
      -webkit-transform: rotate(360deg);
              transform: rotate(360deg); } }
  
  @keyframes spin {
    to {
      -webkit-transform: rotate(360deg);
              transform: rotate(360deg); } }