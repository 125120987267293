.footer {
  width: 100%;
  height: auto;
  margin-top: 0rem;
  padding-top: 10rem;
  // margin-top: 50rem;
  background-color: #041b32;
  .logo {
    width: auto;
    height: 10vh;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .footer-content {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 60vw;
    padding-top: 2rem;
    margin: auto;
  }

  .footer-copy {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 14px;
    margin-top: 3rem;
    padding-bottom: 1rem;
    color: #f1f2f6;
  }

  .subform {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .link {
    color: #ff813b;
    font-weight: 600;
    text-decoration: none;
  }

  .form {
    background-color: #041b32;
    display: flex;
    flex-direction: column;
    width: 35vw;
    border-radius: 10px;
    height: auto;
  }

  .title {
    font-style: normal;
    font-family: "Epilogue";
    font-weight: 400;
    font-size: 22px;
    line-height: 10px;
    color: #f1f2f6;
    padding-top: 2rem;
    text-align: center;
  }

  .formSub {
    text-align: center;
    padding-top: 2em;
    padding-bottom: 2em;
  }

  .label-input {
    display: flex;
    height: auto;
    color: #b5b7ba;
    font-family: "Epilogue";
    font-style: normal;
    font-weight: 400;
    font-size: 13px;
  }

  .email-input {
    width: 371px;
    height: 51px;
    background: #404b57;
    box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.25);
    border-radius: 8px;
    border: none;
    color: #b5b7ba;
    padding-left: 1em;
    font-size: 16px;
    font-family: "Epilogue";
    font-style: normal;
  }

  .email-input:focus {
    outline: none !important;
  }

  ::placeholder {
    color: #b9b6b6;
    // padding-left: 1em;
    // font-family: "Istok Web";
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 29px;
  }

  .submit-btn {
    margin-left: 10px;
    width: 174px;
    height: 51px;
    background: #ff813b;
    border-radius: 8px;
    border: none;
    //text
    font-style: normal;
    font-family: "Epilogue";
    font-weight: 400;
    font-size: 16px;
    color: #f1f2f6;
  }

  .submit-btn:hover {
    cursor: pointer;
    //como era lo del tiempo de animacion?
    background: #ff813b;
    transition: 300ms;
  }

  .subtitle {
    margin-top: 1.2em;
    font-size: 11px;
    text-align: left;
    font-weight: 200;
    color: #b5b7ba;
  }
}

/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
  .footer {
    width: 100vw;
    height: auto;
    margin-top: 0rem;
    padding-top: 10rem;
    background-color: #041b32;

    .logo {
      height: 7vh;
    }

    .footer-content {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      width: 65vw;
      padding-top: 2rem;
      margin: auto;
    }

    .footer-copy {
      width: 65vw;
      text-align: center;
      margin: 0 auto;
      display: flex;
      align-items: center;
      justify-content: center;
      text-align: center;
      font-size: 14px;
      margin-top: 3rem;
      padding-bottom: 3rem;
      color: #f1f2f6;
    }

    .subform {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 85vw;
      margin: 0 auto;
    }

    .link {
      color: #ff813b;
      font-weight: 600;
      text-decoration: none;
    }

    .form {
      background-color: #041b32;
      display: flex;
      flex-direction: column;
      width: 35vw;
      border-radius: 10px;
      height: auto;
    }

    .title {
      font-style: normal;
      font-family: "Epilogue";
      font-weight: 400;
      font-size: 22px;
      line-height: 10px;
      color: #f1f2f6;
      padding-top: 2rem;
      text-align: center;
    }

    .formSub {
      margin: 0 auto;
      // text-align: center;
      padding-top: 2em;
      padding-bottom: 2em;
    }

    .label-input {
      display: flex;
      align-items: center;
      justify-content: center;
      height: auto;
      color: #b5b7ba;
      font-family: "Epilogue";
      font-style: normal;
      font-weight: 400;
      font-size: 13px;
    }

    .email-input {
      width: 48vw;
      height: 51px;
      margin-bottom: 1rem;
      background: #404b57;
      border-radius: 8px;
      border: none;
      color: #b5b7ba;
      // padding-left: 1em;
      font-size: 16px;
      font-family: "Epilogue";
      font-style: normal;
    }

    .email-input:focus {
      outline: none !important;
    }

    ::placeholder {
      color: #b9b6b6;
      // font-family: "Istok Web";
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 29px;
    }

    .submit-btn {
      width: 53vw;
      height: 51px;
      padding-left: 0px;
      margin-left: 0px;
      padding: 0px;
      background: #ff813b;
      border-radius: 8px;
      border: none;
      //text
      font-style: normal;
      font-family: "Epilogue";
      font-weight: 400;
      font-size: 16px;
      color: #f1f2f6;
    }

    .submit-btn:hover {
      cursor: pointer;
      //como era lo del tiempo de animacion?
      background: #ff813b;
      transition: 300ms;
    }

    .subtitle {
      margin: 0 auto;
      width: 51vw;
      margin-top: 1.2em;
      font-size: 11px;
      text-align: left;
      font-weight: 200;
      color: #b5b7ba;
    }
  }
}

// galaxy fold
@media only screen and (max-width: 300px) {
  .footer {
    width: 100vw;
    height: 100vh;
    margin-top: 0rem;
    padding-top: 10rem;
    background-image: url("../../public/assets/benjamin-voros-jv15x2Gs5F8-unsplash.jpg");
    background-size: 100%;
    background-size: cover;
    background-repeat: no-repeat;
    bottom: 0;
    margin-bottom: 0;
    padding-bottom: 0;
    text-align: center;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;

    .title {
      text-align: center;
      font-style: normal;
      font-weight: 400;
      font-size: 38px;
      line-height: 42px;
      padding-left: 0;
      margin: 0 auto;
      color: #ffffff;
      padding-top: 15rem;
      // padding-left: 2rem;
    }

    .formSub {
      // padding-left: 2rem;
      // padding-top: 2em;
      text-align: center;
      margin-top: 3rem;
      margin-left: 0;
      padding-left: 0;
      margin: 1 auto;
    }

    .email-input {
      width: 60vw;
      height: 51px;
      background: rgba(50, 53, 53, 0.83);
      box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.25);
      border-radius: 8px;
      border: none;
      margin-bottom: 1rem;
      margin: 1 auto;
      margin-left: 10px;
    }

    ::placeholder {
      color: #b9b6b6;
      padding-left: 1em;
      // font-family: "Istok Web";
      font-style: normal;
      font-weight: 400;
      font-size: 12px;
      line-height: 29px;
    }

    .submit-btn {
      width: 60vw;
      height: 51px;
      background: #ff813b;
      box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.25);
      border-radius: 8px;
      border: none;
      margin: 1 auto;

      //text
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 32px;
      color: #f3efef;
    }

    .submit-btn:hover {
      cursor: pointer;
      //como era lo del tiempo de animacion?
      background: #ff813b;
    }

    .subtitle {
      margin: auto;
      width: 60vw;
      margin-top: 1em;
      font-size: 8px;
      font-weight: 200;
      color: #ffffff;
    }
  }
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) and (max-width: 768px) {
  .footer {
    width: 100vw;
    height: auto;
    margin-top: 0rem;
    padding-top: 10rem;
    background-color: #041b32;

    .logo {
      height: 7vh;
    }

    .footer-content {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      width: 65vw;
      padding-top: 2rem;
      margin: auto;
    }

    .footer-copy {
      width: 65vw;
      text-align: center;
      margin: 0 auto;
      display: flex;
      align-items: center;
      justify-content: center;
      text-align: center;
      font-size: 14px;
      margin-top: 3rem;
      padding-bottom: 3rem;
      color: #f1f2f6;
    }

    .subform {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 85vw;
      margin: 0 auto;
    }

    .link {
      color: #ff813b;
      font-weight: 600;
      text-decoration: none;
    }

    .form {
      background-color: #041b32;
      display: flex;
      flex-direction: column;
      width: 35vw;
      border-radius: 10px;
      height: auto;
    }

    .title {
      font-style: normal;
      font-family: "Epilogue";
      font-weight: 400;
      font-size: 22px;
      line-height: 10px;
      color: #f1f2f6;
      padding-top: 2rem;
      text-align: center;
    }

    .formSub {
      margin: 0 auto;
      // text-align: center;
      padding-top: 2em;
      padding-bottom: 2em;
    }

    .label-input {
      display: flex;
      align-items: center;
      justify-content: center;
      height: auto;
      color: #b5b7ba;
      font-family: "Epilogue";
      font-style: normal;
      font-weight: 400;
      font-size: 13px;
    }

    .email-input {
      width: 48vw;
      height: 51px;
      margin-bottom: 1rem;
      background: #404b57;
      border-radius: 8px;
      border: none;
      color: #b5b7ba;
      // padding-left: 1em;
      font-size: 16px;
      font-family: "Epilogue";
      font-style: normal;
    }

    .email-input:focus {
      outline: none !important;
    }

    ::placeholder {
      color: #b9b6b6;
      // font-family: "Istok Web";
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 29px;
    }

    .submit-btn {
      width: 50vw;
      height: 51px;
      padding-left: 0px;
      margin-left: 0px;
      padding: 0px;
      background: #ff813b;
      border-radius: 8px;
      border: none;
      //text
      font-style: normal;
      font-family: "Epilogue";
      font-weight: 400;
      font-size: 16px;
      color: #f1f2f6;
    }

    .submit-btn:hover {
      cursor: pointer;
      //como era lo del tiempo de animacion?
      background: #ff813b;
      transition: 300ms;
    }

    .subtitle {
      margin: 0 auto;
      width: 51vw;
      margin-top: 1.2em;
      font-size: 11px;
      text-align: left;
      font-weight: 200;
      color: #b5b7ba;
    }
  }
}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) and (max-width: 990px) {
  .footer {
    width: 100vw;
    height: auto;
    margin-top: 0rem;
    padding-top: 10rem;
    background-color: #041b32;

    .logo {
      height: 7vh;
    }

    .footer-content {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      width: 65vw;
      padding-top: 2rem;
      margin: auto;
    }

    .footer-copy {
      width: 65vw;
      text-align: center;
      margin: 0 auto;
      display: flex;
      align-items: center;
      justify-content: center;
      text-align: center;
      font-size: 14px;
      margin-top: 3rem;
      padding-bottom: 3rem;
      color: #f1f2f6;
    }

    .subform {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 85vw;
      margin: 0 auto;
    }

    .link {
      color: #ff813b;
      font-weight: 600;
      text-decoration: none;
    }

    .form {
      background-color: #041b32;
      display: flex;
      flex-direction: column;
      width: 35vw;
      border-radius: 10px;
      height: auto;
    }

    .title {
      font-style: normal;
      font-family: "Epilogue";
      font-weight: 400;
      font-size: 22px;
      line-height: 10px;
      color: #f1f2f6;
      padding-top: 2rem;
      text-align: center;
    }

    .formSub {
      margin: 0 auto;
      // text-align: center;
      padding-top: 2em;
      padding-bottom: 2em;
    }

    .label-input {
      display: flex;
      align-items: center;
      justify-content: center;
      height: auto;
      color: #b5b7ba;
      font-family: "Epilogue";
      font-style: normal;
      font-weight: 400;
      font-size: 13px;
    }

    .email-input {
      width: 48vw;
      height: 51px;
      margin-bottom: 1rem;
      background: #404b57;
      border-radius: 8px;
      border: none;
      color: #b5b7ba;
      // padding-left: 1em;
      font-size: 16px;
      font-family: "Epilogue";
      font-style: normal;
    }

    .email-input:focus {
      outline: none !important;
    }

    ::placeholder {
      color: #b9b6b6;
      // font-family: "Istok Web";
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 29px;
    }

    .submit-btn {
      width: 50vw;
      height: 51px;
      padding-left: 0px;
      margin-left: 0px;
      padding: 0px;
      background: #ff813b;
      border-radius: 8px;
      border: none;
      //text
      font-style: normal;
      font-family: "Epilogue";
      font-weight: 400;
      font-size: 16px;
      color: #f1f2f6;
    }

    .submit-btn:hover {
      cursor: pointer;
      //como era lo del tiempo de animacion?
      background: #ff813b;
      transition: 300ms;
    }

    .subtitle {
      margin: 0 auto;
      width: 51vw;
      margin-top: 1.2em;
      font-size: 11px;
      text-align: left;
      font-weight: 200;
      color: #b5b7ba;
    }
  }
}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) and (max-width: 1200px) {
  .footer {
    width: 100%;
    // height: 1000px;
    margin-top: 0rem;
    padding-top: 10rem;

    .logo {
      height: 10vh;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .foo {
      margin-right: 3rem;
    }

    .title {
      padding-top: 14rem;
      // padding-left: 4rem;
      font-family: "Jacques Francois";
      font-style: normal;
      font-weight: 400;
      font-size: 60px;
      line-height: 63px;
      color: #ffffff;
    }

    .formSub {
      // padding-left: 4rem;
      padding-top: 1em;
    }

    .email-input {
      width: 371px;
      height: 51px;
      background: rgba(50, 53, 53, 0.83);
      box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.25);
      border-radius: 8px;
      border: none;
    }

    ::placeholder {
      color: #b9b6b6;
      padding-left: 1em;
      // font-family: "Istok Web";
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 29px;
    }

    .submit-btn {
      margin-left: 10px;
      width: 174px;
      height: 51px;
      background: #ff813b;
      box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.25);
      border-radius: 8px;
      border: none;
      //text
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 32px;
      color: #f3efef;
    }

    .submit-btn:hover {
      cursor: pointer;
      //como era lo del tiempo de animacion?
      background: #ff813b;
    }

    .subtitle {
      top: 0;
      padding-top: 0;
      // margin-top: 1em;
      font-size: 14px;
      font-weight: 200;
      color: #ffffff;
    }
  }
}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {
}

@media only screen and (min-width: 768px) and (max-width: 990px) {
  .footer {
    margin-top: 90rem;
  }
}
