.community {
  // background-image: url("../../public/assets/adam-navarro-qXcl3z7_AOc-unsplash.jpg");
  // background-size: 2000px;
  // background-position: 100%;
  // height: 1000px;
  //   text-align: center;
  margin-top: 40rem;
}

.line-com {
  width: 60vw;
  height: 40px;
  background-color: #ff9357;
  border: none;
}

.containerCom {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.title-community {
  font-weight: 400;
  font-size: 90px;
  line-height: 6.5rem;
  color: #041b32;
  margin-top: 15rem;
  text-align: center;
  font-family: "Epilogue";
  font-weight: 700;
}

.span-title {
  color: transparent;
  // -webkit-text-stroke: 3px #FF813B;
  -webkit-text-fill-color: #ff813b;
}

.layout {
  display: flex;
  justify-content: center;
  margin-top: 2rem;
}

.main-photo {
  width: 680px;
  height: 353px;
  border-radius: 8px;
  margin: auto;
  margin-right: 2rem;
  object-fit: cover;
}

.secondary-photo {
  margin-top: 0;
  padding-top: 0;
  border-radius: 8px;
  width: 212px;
  height: 166px;
  object-fit: cover;
}

.parr {
  margin-top: 1rem;
  font-style: normal;
  font-family: "Epilogue";
  font-weight: 400;
  font-size: 20px;
  text-align: justify;
  line-height: 34px;
  color: #041b32;
  width: 680px;
}

/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
  .community {
    // background-image: url("../../public/assets/casey-horner-75_s8iWHKLs-unsplash.jpg");
    // background-size: 100vw;
    // background-repeat: no-repeat;
    // background-position: center top;
    // background-size: cover;
    // height: 120vh;
    //   text-align: center;
  }

  .line-com {
    width: 60vw;
    height: 20px;
    background-color: #ff9357;
    border: none;
  }

  .containerCom {
    width: 100vw;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }
  .title-community {
    font-weight: 400;
    font-size: 36px;
    line-height: 4rem;
    color: #041b32;
    margin-top: 10rem;
    text-align: center;
    font-family: "Epilogue";
    font-weight: 700;
  }

  .span-title {
    color: transparent;
    // -webkit-text-stroke: 3px #FF813B;
    -webkit-text-fill-color: #ff813b;
  }

  .layout {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    margin-top: 5rem;
  }

  .main-photo {
    width: 133px;
    height: 280px;
    border-radius: 8px;
  }

  .secondary-photo {
    padding-top: 0;
    margin-top: 0;
    border-radius: 8px;
    width: 133px;
    height: 133px;
  }

  .text-mobile{
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .parr {
    margin-top: 5rem;
    font-style: normal;
    font-family: "Epilogue";
    font-weight: 400;
    font-size: 15px;
    text-align: justify;
    line-height: 30px;
    color: #041b32;
    width: 80vw;
  }
}

//galaxy fold
@media only screen and (max-width: 300px) {
  .community {
    background-image: url("../../public/assets/casey-horner-75_s8iWHKLs-unsplash.jpg");
    background-size: 100vw;
    background-repeat: no-repeat;
    background-position: center top;
    background-size: cover;
    height: 100vh;
    //   text-align: center;
  }

  .containerCom {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }
  .title-community {
    padding-left: 5px;
    margin: 0 auto;
    max-width: 80vw;
    font-weight: 400;
    font-size: 18px;
    line-height: 22px;
    color: #e6e6e6;
    margin-top: 20rem;
  }

  .parr {
    padding-left: 5px;
    margin-top: 2rem;
    max-width: 80vw;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;
    color: #ffffff;
    width: 690px;
  }
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) and (max-width: 1200px) {
  .community {
    // background-image: url("../../public/assets/casey-horner-75_s8iWHKLs-unsplash.jpg");
    // background-size: 100vw;
    // background-repeat: no-repeat;
    // background-position: center top;
    // background-size: cover;
    // height: 100vh;
    //   text-align: center;
  }

  .line-com {
    width: 60vw;
    height: 40px;
    background-color: #ff9357;
    border: none;
  }

  .containerCom {
    width: 100vw;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    margin-top: 10rem;
  }
  .title-community {
    font-weight: 400;
    font-size: 55px;
    line-height: 4.3rem;
    color: #041b32;
    margin-top: 8rem;
    text-align: center;
    font-family: "Epilogue";
    font-weight: 700;
  }

  .layout {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    margin-top: 5rem;
  }

  .main-photo {
    width: 300px;
    height: 350px;
    border-radius: 8px;
  }

  .secondary-photo {
    padding-top: 0;
    margin-top: 0;
    border-radius: 8px;
    width: 180px;
    height: 165px;
  }

  .text-mobile{
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .parr {
    margin-top: 2rem;
    // font-style: normal;
    // font-family: "Epilogue";
    font-weight: 400;
    font-size: 20px;
    text-align: justify;
    line-height: 32px;
    color: #041b32;
    width: 66vw;
  }
}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) and (max-width: 992px) {
  .community {
    // background-image: url("../../public/assets/casey-horner-75_s8iWHKLs-unsplash.jpg");
    background-size: cover;
    background-position: 100%;
    height: 100vh;
    margin-top: 30rem;
    //   text-align: center;
  }

  .containerCom {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    // margin-top: 10rem;
  }
  .title-community {
    // font-weight: 400;
    // font-size: 36px;
    // line-height: 4rem;
    // color: #041b32;
    // margin-top: 10rem;
    // text-align: center;
    // font-family: "Epilogue";
    // font-weight: 700;
  }

  .parr {
    // margin-top: 5rem;
    // font-style: normal;
    // font-family: "Epilogue";
    // font-weight: 400;
    // font-size: 13px;
    // text-align: justify;
    // line-height: 30px;
    // color: #041b32;
    // width: 80vw;
  }
}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) and (max-width: 1300px) {
  
}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {
}
