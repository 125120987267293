.nav-link-2 {
    text-decoration: none;
    display: inline-block;
    position: relative;
    color: #f1f2f6;
    font-size: 22px;
    margin-right: 2rem;
    margin: 2.2rem;
  }
  
  .nav-link-2:hover {
    opacity: 1;
  }
  
  .nav-link-2::before {
    transition: 300ms;
    height: 2px;
    content: "";
    position: absolute;
    background-color: #ff9357;
  }
  
  .nav-link-ltr::before {
    width: 0%;
    bottom: -5px;
  }
  
  .nav-link-ltr:hover::before {
    width: 100%;
  }

  .menu-items a{
    text-decoration: none;
    color: #f1f2f6;
    transition: color 0.3s ease-in-out;
    list-style: none;
  }