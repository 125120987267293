.text {
  z-index: 10;
  position: absolute;
  background: rgba(4, 27, 50, 0.58);
  border-radius: 10px;
  width: 43vw;
  height: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 3rem;
  margin-top: 22rem;
  margin-bottom: 100rem;
}

.line-car {
  width: 75%;
  height: 3px;
  border-radius: 10px;
  color: #ff9357;
}

.containerCarr {
  display: flex;
  // align-items: center;
  justify-content: center;
  height: fit-content;
  // margin-bottom: 30rem;
}

.button-blog {
  background: #ff813c;
  border-radius: 56px;
  width: 20vw;
  height: 7vh;
  border: none;
  margin-top: 2rem;
  margin-bottom: 3rem;
  //text
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 32px;
  color: #f1f2f6;
}

.button-blog:hover {
  cursor: pointer;
  //como era lo del tiempo de animacion?
  background: #ff813c;
  opacity: 100%;
  transition: 500ms;
}

.title-car {
  font-style: normal;
  font-weight: 400;
  font-size: 36px;
  line-height: 47px;
  color: #ffffff;
  text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}

.par-car {
  font-style: normal;
  font-weight: 400;
  font-size: 26px;
  line-height: 40px;
  text-align: center;
  color: #f1f2f6;
  width: 75%;
  height: 20vh;
  // margin-top: 2rem;
  margin-bottom: 2.5rem;
}

/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
  .text {
    z-index: 10;
    position: absolute;
    background: rgba(4, 27, 50, 0.58);
    border-radius: 10px;
    width: 82vw;
    height: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 3rem;
    margin-top: 100rem;
    margin-bottom: 100rem;
  }

  .logotipo-blog {
    object-fit: cover;
    width: 30vh;
  }

  .carroussel {
    z-index: 1;
    position: absolute;
    width: 100vw;
  }

  .hide-mobile {
    display: none;
  }

  .is-mobile {
    height: 100vh;
    width: 100vw;
    background-size: cover;
    background-image: url("../../public/assets/mobile/dario-bronnimann-tu2apPbNQTs-unsplash.jpg");
    // margin-top: 5rem;
  }

  .containerCarr {
    display: flex;
    align-items: center;
    justify-content: center;
    // margin-top: 6rem;
  }

  .button-blog {
    background: #ff813c;
    border-radius: 56px;
    width: 55vw;
    height: 6vh;
    border: none;
    margin-top: 2rem;
    margin-bottom: 3rem;
    //text
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 32px;
    color: #f1f2f6;
  }

  .button-blog:hover {
    cursor: pointer;
    //como era lo del tiempo de animacion?
    background: #ff813c;
    opacity: 100%;
    transition: 500ms;
  }

  .title-car {
    font-style: normal;
    font-weight: 400;
    font-size: 36px;
    line-height: 47px;
    color: #ffffff;
    text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  }

  .par-car {
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    line-height: 20px;
    text-align: center;
    color: #efefef;
    text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    width: 65vw;
    // height: 25vh;
    height: auto;
    margin-top: 0.5rem;
    // margin-bottom: 5rem;
  }
}

//galaxy fold
@media only screen and (max-width: 300px) {
  .text {
    z-index: 10;
    position: absolute;
    background: rgba(255, 255, 255, 0.411);
    border-radius: 5px;
    width: 80vw;
    height: 70vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 3rem;
    margin-bottom: 20rem;
  }

  .carroussel {
    z-index: 1;
    position: absolute;
    width: 100vw;
  }

  .hide-mobile {
    visibility: hidden;
  }

  .containerCarr {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .button-blog {
    background: #ff813c;
    box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.25);
    border-radius: 8px;
    width: 55vw;
    height: 5vh;
    border: none;
    margin-top: 6rem;

    //text
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    line-height: 32px;
    color: #f3efef;
  }

  .button-blog:hover {
    cursor: pointer;
    //como era lo del tiempo de animacion?
    background: rgba(79, 162, 181, 1);
    opacity: 100%;
    transition: 500ms;
  }

  .title-car {
    font-style: normal;
    font-weight: 400;
    font-size: 36px;
    line-height: 47px;
    color: #ffffff;
    text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  }

  .par-car {
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    line-height: 30px;
    text-align: center;
    color: #efefef;
    text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    width: 60vw;
    height: 25vh;
    margin-top: 3rem;
    margin-bottom: 4rem;
  }
}

//600 - 768
@media only screen and (min-width: 600px) and (max-width: 768px) {
  .text {
    z-index: 10;
    position: absolute;
    background: rgba(4, 27, 50, 0.58);
    border-radius: 10px;
    width: 82vw;
    height: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 3rem;
    margin-top: 150rem;
    margin-bottom: 100rem;
  }

  .logotipo-blog {
    object-fit: cover;
    width: 30vh;
  }

  .carroussel {
    z-index: 1;
    position: absolute;
    width: 100vw;
  }

  .hide-mobile {
    display: none;
  }

  .is-mobile {
    height: 100vh;
    width: 100vw;
    background-size: cover;
    background-image: url("../../public/assets/mobile/dario-bronnimann-tu2apPbNQTs-unsplash.jpg");
    margin-top: 50rem;
  }

  .containerCarr {
    display: flex;
    align-items: center;
    justify-content: center;
    // margin-top: 6rem;
  }

  .button-blog {
    background: #ff813c;
    border-radius: 56px;
    width: 55vw;
    height: 6vh;
    border: none;
    margin-top: 2rem;
    margin-bottom: 3rem;
    //text
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 32px;
    color: #f1f2f6;
  }

  .button-blog:hover {
    cursor: pointer;
    //como era lo del tiempo de animacion?
    background: #ff813c;
    opacity: 100%;
    transition: 500ms;
  }

  .title-car {
    font-style: normal;
    font-weight: 400;
    font-size: 36px;
    line-height: 47px;
    color: #ffffff;
    text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  }

  .par-car {
    font-style: normal;
    font-weight: 400;
    font-size: 19px;
    line-height: 27px;
    text-align: center;
    color: #efefef;
    text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    width: 65vw;
    // height: 25vh;
    height: auto;
    // margin-top: 0.5rem;
    // margin-bottom: 5rem;
  }
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 768px) and (max-width: 1200px) {
  .text {
    z-index: 10;
    position: absolute;
    background: rgba(4, 27, 50, 0.58);
    border-radius: 10px;
    width: 80vw;
    height: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 3rem;
    margin-top: 22rem;
    margin-bottom: 100rem;
  }

  .par-car {
    font-style: normal;
    font-weight: 400;
    font-size: 21px;
    line-height: 40px;
    text-align: center;
    color: #f1f2f6;
    width: 75%;
    height: 20vh;
    margin-top: 2rem;
    // margin-bottom: 2.5rem;
  }

  .button-blog {
    background: #ff813c;
    border-radius: 56px;
    width: 35vw;
    height: 7vh;
    border: none;
    margin-top: 2.8rem;
    margin-bottom: 3rem;
    //text
    font-style: normal;
    font-weight: 400;
    font-size: 22px;
    line-height: 32px;
    color: #f1f2f6;
  }

  .button-blog:hover {
    cursor: pointer;
    //como era lo del tiempo de animacion?
    background: #ff813c;
    opacity: 100%;
    transition: 500ms;
  }

  .hide-mobile {
    display: none;
  }

  .is-mobile {
    height: 100vh;
    width: 100vw;
    background-size: cover;
    background-image: url("../../public/assets/mobile/dario-bronnimann-tu2apPbNQTs-unsplash.jpg");
    // margin-top: 5rem;
  }

  // .text {
  //   z-index: 10;
  //   position: absolute;
  //   background: rgba(255, 255, 255, 0.411);
  //   border-radius: 5px;
  //   width: 80vw;
  //   height: 60vh;
  //   display: flex;
  //   flex-direction: column;
  //   align-items: center;
  //   padding-top: 4rem;
  // }

  // .carroussel {
  //   z-index: 1;
  //   position: absolute;
  //   width: 100vw;
  // }

  // .hide-mobile {
  //   display: none;
  // }

  // .containerCarr {
  //   display: flex;
  //   align-items: center;
  //   justify-content: center;
  // }

  // .logotipo-blog {
  //   object-fit: cover;
  //   width: 30vh;
  // }

  // .is-mobile {
  //   height: 100vh;
  //   width: 100vw;
  //   background-size: cover;
  //   background-image: url("../../public/assets/mobile/dario-bronnimann-tu2apPbNQTs-unsplash.jpg");
  // }

  // .button-blog {
  //   background: #f88f22;
  //   box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.25);
  //   border-radius: 8px;
  //   width: 55vw;
  //   height: 5vh;
  //   border: none;
  //   margin-top: 2rem;

  //   //text
  //   font-style: normal;
  //   font-weight: 400;
  //   font-size: 16px;
  //   line-height: 32px;
  //   color: #f3efef;
  // }

  // .button-blog:hover {
  //   cursor: pointer;
  //   //como era lo del tiempo de animacion?
  //   background: rgba(79, 162, 181, 1);
  //   opacity: 100%;
  //   transition: 500ms;
  // }

  // .title-car {
  //   font-style: normal;
  //   font-weight: 400;
  //   font-size: 36px;
  //   line-height: 47px;
  //   color: #ffffff;
  //   text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  // }

  // .par-car {
  //   font-style: normal;
  //   font-weight: 400;
  //   font-size: 16px;
  //   line-height: 40px;
  //   text-align: center;
  //   color: #efefef;
  //   text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  //   width: 60vw;
  //   height: 25vh;
  //   margin-top: 3rem;
  //   bottom: 0;
  //   padding-bottom: 0;
  // }
}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) and (max-width: 990px) {
  .text {
    z-index: 10;
    position: absolute;
    background: rgba(4, 27, 50, 0.58);
    border-radius: 10px;
    width: 80vw;
    height: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 3rem;
    margin-top: 65rem;
    margin-bottom: 100rem;
  }

  .par-car {
    font-style: normal;
    font-weight: 400;
    font-size: 21px;
    line-height: 40px;
    text-align: center;
    color: #f1f2f6;
    width: 75%;
    height: 20vh;
    margin-top: 2rem;
    // margin-bottom: 2.5rem;
  }

  .button-blog {
    background: #ff813c;
    border-radius: 56px;
    width: 35vw;
    height: 7vh;
    border: none;
    margin-top: 2.8rem;
    margin-bottom: 3rem;
    //text
    font-style: normal;
    font-weight: 400;
    font-size: 22px;
    line-height: 32px;
    color: #f1f2f6;
  }

  .button-blog:hover {
    cursor: pointer;
    //como era lo del tiempo de animacion?
    background: #ff813c;
    opacity: 100%;
    transition: 500ms;
  }

  .hide-mobile {
    display: none;
  }

  .is-mobile {
    height: 100vh;
    width: 100vw;
    background-size: cover;
    background-image: url("../../public/assets/mobile/dario-bronnimann-tu2apPbNQTs-unsplash.jpg");
    margin-top: 50rem;
  }
}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) and (max-width: 1300px) {
  .text {
    z-index: 10;
    position: absolute;
    background: rgba(4, 27, 50, 0.58);
    border-radius: 10px;
    width: 65vw;
    height: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 3rem;
    margin-top: 100rem;
    // margin-bottom: 100rem;
  }

  .logotipo-blog {
    object-fit: cover;
    width: 40vh;
  }

  .carroussel {
    z-index: 1;
    position: absolute;
    width: 100vw;
  }

  .hide-mobile {
    display: none;
  }

  .is-mobile {
    height: 100vh;
    width: 100vw;
    background-size: cover;
    background-image: url("../../public/assets/mobile/dario-bronnimann-tu2apPbNQTs-unsplash.jpg");
    // margin-top: 5rem;
  }

  .containerCarr {
    display: flex;
    align-items: center;
    justify-content: center;
    // margin-top: 6rem;
  }

  .button-blog {
    background: #ff813c;
    border-radius: 56px;
    width: 45vw;
    height: 6vh;
    border: none;
    margin-top: 0.8rem;
    margin-bottom: 3rem;
    //text
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 32px;
    color: #f1f2f6;
  }

  .button-blog:hover {
    cursor: pointer;
    //como era lo del tiempo de animacion?
    background: #ff813c;
    opacity: 100%;
    transition: 500ms;
  }

  .title-car {
    font-style: normal;
    font-weight: 400;
    font-size: 36px;
    line-height: 47px;
    color: #ffffff;
    text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  }

  .par-car {
    width: 55vw;
    font-style: normal;
    font-weight: 400;
    font-size: 17px;
    line-height: 22px;
    text-align: center;
    color: #efefef;
    text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    // height: 25vh;
    height: auto;
    margin-top: 2rem;
    // margin-bottom: 5rem;
  }
}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {
}

@media only screen and (min-width: 1200px) and (max-width: 1625px) {
  .text {
    z-index: 10;
    position: absolute;
    background: rgba(4, 27, 50, 0.58);
    border-radius: 10px;
    width: 43vw;
    height: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 3rem;
    margin-top: 11rem;
    margin-bottom: 100rem;
  }

  .line-car {
    width: 75%;
    height: 3px;
    border-radius: 10px;
    color: #ff9357;
  }

  .containerCarr {
    display: flex;
    // align-items: center;
    justify-content: center;
    height: fit-content;
    // margin-bottom: 30rem;
  }

  .button-blog {
    background: #ff813c;
    border-radius: 56px;
    width: 20vw;
    height: 7vh;
    border: none;
    margin-top: 2rem;
    margin-bottom: 3rem;
    //text
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 32px;
    color: #f1f2f6;
  }

  .button-blog:hover {
    cursor: pointer;
    //como era lo del tiempo de animacion?
    background: #ff813c;
    opacity: 100%;
    transition: 500ms;
  }

  .title-car {
    font-style: normal;
    font-weight: 400;
    font-size: 36px;
    line-height: 47px;
    color: #ffffff;
    text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  }

  .par-car {
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 40px;
    text-align: center;
    color: #f1f2f6;
    width: 75%;
    height: auto;
    // margin-top: 2rem;
    margin-bottom: -1rem;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1300px) {
  .text {
    z-index: 10;
    position: absolute;
    background: rgba(4, 27, 50, 0.58);
    border-radius: 10px;
    width: 43vw;
    height: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 3rem;
    margin-top: 100rem;
    margin-bottom: 100rem;
  }
}
